<template>
  <div class="no-padding">
    <b-select v-model="computedValue" :placeholder="placeholder" expanded>
      <option v-if="!required && !computedValue" class="nofilter" value>{{ placeholder }}</option>
      <option v-for="option in options" :value="option.id" :key="option.id">{{ option.nama }}</option>
    </b-select>
  </div>
</template>

<script>
import APP_CONFIG from "@/apps/core/modules/config.js";
import axios from "axios";

export default {
  name: "GenericSelect",
  props: {
    apiPath: String,
    default: { type: Boolean, default: false },
    params: {},
    placeholder: { type: String, default: null },
    required: { type: Boolean, default: true },
    value: String // uuid,
  },
  data() {
    return {
      valueData: this.value,
      options: [],
      optionsMap: {}
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.valueData;
      },
      set(value) {
        const realValue = value === "" ? null : value;
        const name = value === "" ? null : this.optionsMap[value];
        this.valueData = value;
        this.$emit("select-name", name);
        this.$emit("input", realValue);
      }
    }
  },
  methods: {
    fetchData() {
      const apiURL = `${APP_CONFIG.baseAPIURL}${this.apiPath}`;
      const params = this.params ? this.params : {};
      axios
        .get(apiURL, { params })
        .then(response => {
          this.optionsMap = response.data.reduce(
            (res, val) => ({ ...res, [val.id]: val.nama }),
            {}
          );
          this.options = response.data;
          if (this.options.length > 0 && this.default) {
            const defaultVal = this.options[0];
            this.valueData = defaultVal.id;
            this.$emit("select-name", defaultVal.nama);
            this.$emit("input", this.valueData);
          }
        })
        .catch(error => {
          this.options = [];
          return Promise.reject(error);
        });
    }
  },
  watch: {
    // When v-model is changed: update internal value
    value(value) {
      if (this.valueData !== value) {
        this.valueData = value;
      }
    }
  },
  created() {
    this.fetchData();
  }
};
</script>

<style scoped lang="scss">
::v-deep option.nofilter {
  color: #999;
}
</style>
